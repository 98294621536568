
var ccnav = $('#company-card_navigation');
if (ccnav.length) {
    $(window).bind('scroll', function () {
        adjustCompanyCardMenu();
    });

    $(window).bind('resize', function () {
        adjustCompanyCardMenu();
        if ($(window).width() > 755 && !$('#js-toggle-mobile-search-input').hasClass('tw-hidden')) {
            pageOverlay.deactivate();
        }
    });

    function adjustCompanyCardMenu() {
        var offset = ($('#company-card_top-banner').length ? $('#company-card_top-banner').height() : 0) +
            ($('header').length ? $('header').height() : 0) +
            ($('#company-card_top-banner').length ? parseInt($('#company-card_top-banner').css('padding-top')) : 0) +
            ($('#company-card_top-banner').length ? parseInt($('#company-card_top-banner').css('padding-bottom')) : 0);
        if ($(window).scrollTop() > (offset - $('header').height())) {
            ccnav.addClass('fixed-company-nav');
            $('#company-card_container').addClass('company-card_container-fixed-margin');
            $('#company-card_navigation').css('top', $('header').height());
        } else {
            ccnav.removeClass('fixed-company-nav');
            $('#company-card_container').removeClass('company-card_container-fixed-margin');

            $('#company-card_navigation').css('top',
                $('#company-card_navigation').hasClass('fixed-company-nav') || $(window).width() < 755 ? $('header').height() : '0px'
            );
        }
    }

    function adjustStickySidebar() {
        if($('#side_ad_c').height() > (window.innerHeight - (16*7.5))){
            $('#side_ad_c').css({ top: window.innerHeight - $('#side_ad_c').height() - 10 });
        }

    }

    const observer = new ResizeObserver(adjustStickySidebar);
    observer.observe(document.getElementById("side_ad_c"));

}
