(function (window) {
    'use strict';

    var $navigation = $('#company-card_navigation');

    window.addEventListener('replaceState', function (event) {

        setActive($($navigation)[0], event.detail);
        setActive($($navigation)[1], event.detail);

    });

    function setActive($menu, $currentURL) {
        $($menu).find('li').each(function () {


            var $loopLink = $(this).find('a')[0];
            var $loopLinkUrl = $($loopLink).attr('href');


            if ($loopLinkUrl == $currentURL) {
                $(this).addClass('current');
            } else {
                $(this).removeClass('current');
            }

        });
    }

})(window);
