(function (window) {
    'use strict';

    window.addEventListener('replaceState', function (event) {
        var metaToFindArr = event.detail.split('/');
        var metaToFind = metaToFindArr[metaToFindArr.length - 1];

        var updateMeta = $('#' + metaToFind + ' .updateMeta');
        if (!updateMeta.length) {
            return;
        }
        $("title").remove();
        $("meta[name=description]").remove();
        $("meta[name=keywords]").remove();
        var newDivs = decodeURIComponent(updateMeta.html());
        $("head").append(newDivs);

    });
})(window);
